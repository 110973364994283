<template>
  <v-card class="mx-auto pa-2" style="max-height: 95vh" width="260" tile>
    <v-list class="py-0">
      <v-list-item dense>
        <v-list-item-title class="primary--text body-1" style="font-weight:600">
          {{ $t('chatSidebar.cooperation') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-for="status in cooperation" :key="status.id" @click="setCooperationStatus(status.id)" dense>
        <v-avatar :color="status.color" class="mr-2 elevation-2" size="12" />
        <div class="body-2" :class="statusId == status.id ? 'font-weight-bold' : ''">{{ cooperationStatuses[status.id].description }}</div>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>{{ $t('chatSidebar.show') }}</v-list-item-title>
        <v-list-item-action class="my-1">
          <v-switch v-model="show" color="primary" @change="showCooperationStatus" dense></v-switch>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-divider class="mb-3" />
    <v-list class="pt-0">
      <v-list-item dense @click="goToArchive">
        <v-list-item-title>{{ $t('chatSidebar.archive') }}</v-list-item-title>
      </v-list-item>
      <v-list-item dense @click="removeContact()">
        <v-list-item-title>{{ $t('chatSidebar.delete') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
import Service from '@/services'
import * as cooperationStatus from '@/types/cooperation-status.js'
import * as colors from '@/types/status-colors.js'
export default {
  name: 'ContactMenu',
  props: ['userId', 'userName', 'statusId', 'showStatus'],
  data () {
    return {
      loading: false,
      statusShow: this.showStatus,
      cooperation: [
        {
          id: cooperationStatus.RELIABLE,
          color: colors.RELIABLE
        },
        {
          id: cooperationStatus.WITHRESERVATIONS,
          color: colors.WITHRESERVATIONS
        },
        {
          id: cooperationStatus.UNRELIABLE,
          color: colors.UNRELIABLE
        },
        {
          id: cooperationStatus.NONE,
          color: colors.NONE
        }
      ]
    }
  },
  computed: {
    cooperationStatuses () {
      return this.$store.getters.enums.cooperationStatuses
    },
    show: {
      get () {
        return this.statusShow
      },
      set (newValue) {
        this.statusShow = newValue
      }
    }
  },
  methods: {
    async setCooperationStatus (status) {
      this.loading = true

      try {
        await Service.put('/Contact/UpdateContact', {
          userId: this.userId,
          showCooperationStatus: this.statusShow,
          cooperationStatus: status
        })
        this.$emit('update')
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('chatSidebar.change')
        )
      } catch (e) {}

      this.loading = false
    },
    async showCooperationStatus () {
      this.loading = true

      try {
        await Service.put('/Contact/UpdateContact', {
          userId: this.userId,
          showCooperationStatus: this.statusShow,
          cooperationStatus: this.statusId
        })
        this.$emit('update')
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('chatMenu.successNotification')
        )
      } catch (e) {}

      this.loading = false
    },
    goToArchive () {
      const path = '/archive/' + this.userName
      this.$router.push({ path })
    },
    async removeContact () {
      this.loading = true

      try {
        await Service.delete('/Contact/DeleteContact', { userId: this.userId })
        this.$emit('update')
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('chatSidebar.deleted')
        )
      } catch (e) {}

      this.loading = false
    }
  }
}
</script>
<style scoped></style>
