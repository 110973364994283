<template>
  <div
    v-if="contact"
    class="d-flex justify-space-between pt-2 pb-3 contact"
    @click="$emit('open', contact.user.id)"
  >
    <div class="d-flex justify-space-between">
      <div>
        <StatusAvatar
          :userId="contact.user.id"
          :size="30"
          showStatus
          class="mr-3 mt-2"
        ></StatusAvatar>
      </div>
      <div class="d-flex flex-column">
        <h5>{{ contact.user.fullName | trimText(30) }}</h5>
        <span class="company">{{ contact.companyName | trimText(30) }}</span>
        <span v-if="user && user.showChatDescription" class="company">{{ user.chatDescription | trimText(30) }}</span>
        <v-spacer></v-spacer>
        <span class="company">tel. {{ contact.user.phone }}</span>
      </div>
    </div>
    <div class="d-flex align-center">
      <v-menu :close-on-content-click="false" bottom left>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </div>
        </template>
        <ContactMenu :userId="contact.user.id" :userName="contact.user.name" :statusId="contact.cooperationStatus" :showStatus="contact.showCooperationStatus" @update="$emit('update')"></ContactMenu>
      </v-menu>
    </div>
  </div>
</template>
<script>
import StatusAvatar from '@/components/StatusAvatar.vue'
import ContactMenu from './ContactMenu.vue'
export default {
  name: 'Contact',
  components: { StatusAvatar, ContactMenu },
  props: ['contact'],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    user () {
      return this.$store.getters['users/users'].find((el) => el.id === this.contact.user.id)
    }
  },
  methods: {}
}
</script>
<style>
.contact {
  position: relative;
  min-height: 80px;
  /* border-top: solid 0.1px rgba(52, 58, 61, 0.1); */
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.contact h5 {
  font-weight: 600;
  color: var(--v-primary-base);
}
.contact:hover h5 {
  color: var(--v-accent-base);
}
.company {
  font-size: 10px;
}
.contact:hover {
  color: white;
  background-color: var(--v-primary-base);
}
.contact:hover .v-avatar {
  border: solid thin white !important;
}
.contact:hover .company {
  color: white;
}
.contact:after {
  content: "";
  border-top: 2px solid #E6E6E6;
  bottom: 0px;
  left: 15px;
  width: calc(100% - 30px);
  position: absolute;
}
</style>
